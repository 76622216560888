<template>
  <li
    v-if="canViewVerticalNavMenuLink(item)"
    class="nav-item"
    :class="{
      'active': isActive,
      'disabled': item.disabled
    }"
  >
    <b-link
      v-bind="linkProps"
      class="d-flex align-items-center"
    >
      <!-- <feather-icon :icon="item.icon || 'CircleIcon'" /> -->
      <feather-icon
        :icon="item.icon || ''"
      />

      <svg
        v-if="item.src == `demographics-icon-white.svg`"
        id="demographics-icon-white"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="21"
        height="15.66"
        viewBox="0 0 21 15.66"
      >
        <defs>
          <clipPath id="clip-path">
            <rect
              id="Rectangle_1547"
              data-name="Rectangle 1547"
              width="21"
              height="15.66"
              fill="currentColor"
            />
          </clipPath>
        </defs>
        <g
          id="Group_2411"
          data-name="Group 2411"
          clip-path="url(#clip-path)"
        >
          <path
            id="Path_629"
            data-name="Path 629"
            d="M18.381,47.86H14.234a1.082,1.082,0,0,0-.226.01.6.6,0,0,0,.009,1.195,1.154,1.154,0,0,0,.223.009h4.287A1.061,1.061,0,0,1,19.7,50.11q0,4.378,0,8.755A1.065,1.065,0,0,1,18.5,59.9h-8l-2.2,0c-1.893,0-3.851-.006-5.776.012a1.3,1.3,0,0,1-.926-.372.982.982,0,0,1-.305-.7c.018-2.112.015-4.259.012-6.336q0-1.185,0-2.371a1.064,1.064,0,0,1,1.2-1.061H3.556c1.058,0,2.151,0,3.228-.008a.952.952,0,0,0,.605-.224.623.623,0,0,0,.1-.606c-.06-.172-.226-.376-.676-.376H4.995c-.806,0-1.64,0-2.459,0A2.352,2.352,0,0,0,0,50.163c0,3.189,0,6.021,0,8.655a2.331,2.331,0,0,0,.1.653,2.417,2.417,0,0,0,2.469,1.645h15.8A2.375,2.375,0,0,0,21,58.743q0-4.252,0-8.5a2.374,2.374,0,0,0-2.619-2.378"
            transform="translate(0 -45.456)"
            fill="currentColor"
          />
          <path
            id="Path_630"
            data-name="Path 630"
            d="M158.289,4.4c.52.006,1.052.009,1.591.009s1.062,0,1.6-.009A.979.979,0,0,0,162.486,3.4c.012-.4.012-.8,0-1.191a.953.953,0,0,0-.769-.958l-.029-.009c0-.079,0-.161,0-.243a.981.981,0,0,0-.991-.987c-.546-.015-1.09-.015-1.617,0a.978.978,0,0,0-.986.993c0,.078,0,.155,0,.23a.974.974,0,0,0-.8.971c-.009.426-.009.815,0,1.189a.977.977,0,0,0,1,1.01m.8-3.012h.2V1.213h1.193v.179h-1.394Zm-.606,1.031h2.8V3.2h-2.8Z"
            transform="translate(-149.387 0)"
            fill="currentColor"
          />
          <path
            id="Path_631"
            data-name="Path 631"
            d="M77.73,116.176a1.035,1.035,0,0,0-.848.821,7.408,7.408,0,0,0-.143,1.039.622.622,0,0,0,.148.468.581.581,0,0,0,.417.192h.024a.6.6,0,0,0,.584-.632,4.239,4.239,0,0,1,.086-.678.217.217,0,0,1,.091-.077c.122-.042.249-.073.384-.1s.254-.061.381-.1c.1-.033.151-.027.238.075a5.373,5.373,0,0,0,.4.412l.087.083a.608.608,0,0,0,.932.01l.1-.09a3.524,3.524,0,0,0,.312-.311c.171-.2.3-.239.5-.148a1.615,1.615,0,0,0,.423.112l.085.015a.263.263,0,0,1,.062.017.143.143,0,0,1,0,.028c0,.132.013.263.022.39.008.1.016.208.019.31a.613.613,0,0,0,.579.66.557.557,0,0,0,.4-.164.684.684,0,0,0,.194-.5v-.01a5.533,5.533,0,0,0-.043-.793,1.085,1.085,0,0,0-.724-1.016,13.335,13.335,0,0,0-1.3-.3.621.621,0,0,0-.209,0,2.234,2.234,0,1,0-1.949-.016c-.034.006-.068.013-.1.022-.347.093-.751.2-1.15.288m2.244-3.44a1.129,1.129,0,1,1-1.129,1.129,1.13,1.13,0,0,1,1.129-1.129m0,3.362a2.225,2.225,0,0,0,.631-.091c-.021.013-.041.026-.059.039a4.042,4.042,0,0,0-.45.4l-.055.053-.027-.028c-.115-.12-.224-.233-.322-.35l-.04-.044a2.239,2.239,0,0,0,.321.023"
            transform="translate(-72.885 -106.03)"
            fill="currentColor"
          />
          <path
            id="Path_632"
            data-name="Path 632"
            d="M234.3,136.11h-2.688q-.75,0-1.5,0a.627.627,0,0,0-.7.575.551.551,0,0,0,.154.407.747.747,0,0,0,.547.208H234.3a.6.6,0,1,0,0-1.189"
            transform="translate(-217.9 -129.278)"
            fill="currentColor"
          />
          <path
            id="Path_633"
            data-name="Path 633"
            d="M234.3,176.1h-2.7c-.5,0-1.024,0-1.537,0a.595.595,0,1,0-.022,1.187c.023,0,.046,0,.069,0h4.206a.756.756,0,0,0,.549-.2.553.553,0,0,0,.157-.4c-.008-.348-.3-.582-.719-.583"
            transform="translate(-217.906 -167.258)"
            fill="currentColor"
          />
          <path
            id="Path_634"
            data-name="Path 634"
            d="M234.337,216.094h-4.214a.756.756,0,0,0-.55.205.55.55,0,0,0-.156.4c.009.348.3.582.719.583h3.174c.338,0,.68,0,1.018-.011a.828.828,0,0,0,.547-.246.619.619,0,0,0,.077-.564.57.57,0,0,0-.617-.374"
            transform="translate(-217.904 -205.249)"
            fill="currentColor"
          />
        </g>
      </svg>

      <b-img
        v-else-if="item.src"
        :src="require(`@/assets/images/icons/${item.src}`) || ''"
        fluid
        alt="img"
        class="mr-1 c-icons"
      />

      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
import { useUtils as useAclUtils } from '@core/libs/acl'
import { BLink, BBadge, BImg } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'

export default {
  components: {
    BLink,
    BBadge,
    BImg,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item)
    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
    }
  },

}
</script>
