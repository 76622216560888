/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
// import dashboard from './dashboard'
// import appsAndPages from './apps-and-pages'
// import others from './others'
// import chartsAndMaps from './charts-and-maps'
// import uiElements from './ui-elements'
// import formAndTable from './forms-and-table'

// Array of sections
// export default [...dashboard, ...appsAndPages, ...uiElements, ...formAndTable, ...chartsAndMaps, ...others]
// import { getUserData } from '@/auth/utils'

// const userData = getUserData()

export default [
  {
    title: 'Dashboard',
    icon: 'HomeIcon',
    route: 'landing',
    action: 'read',
    resource: 'AdminMenu',
  },
  {
    title: 'Clients',
    icon: 'UserIcon',
    route: 'clients',
    action: 'read',
    resource: 'AdminMenu',
  },
  {
    title: 'Caregiver',
    icon: 'HeartIcon',
    route: 'caregiver',
    action: 'read',
    resource: 'AdminMenu',
  },
  /* {
    title: 'Carefinder',
    icon: 'LifeBuoyIcon',
    route: 'carefinder',
    action: 'read',
    resource: 'AdminMenu',
  }, */
  /* {
    title: 'Accounting',
    icon: 'DollarSignIcon',
    route: 'accounting',
    action: 'read',
    resource: 'AdminMenu',
  }, */
  /* {
    title: 'SMS',
    icon: 'MailIcon',
    route: 'sms',
    action: 'read',
    resource: 'AdminMenu',
  }, */
  {
    title: 'HR',
    icon: 'UsersIcon',
    route: 'hr',
    action: 'read',
    resource: 'AdminMenu',
  },
  /* {
    title: 'Reports',
    icon: 'FileTextIcon',
    route: 'reports',
    action: 'read',
    resource: 'AdminMenu',
  }, */
  {
    title: 'History',
    icon: 'ClockIcon',
    route: 'history',
    action: 'read',
    resource: 'AdminMenu',
  },
  {
    title: 'Caregiver Dashboard',
    icon: 'HomeIcon',
    route: 'caregiverLanding',
    action: 'read',
    resource: 'CaregiverMenu',
  },
  {
    title: 'COC Forms',
    // icon: 'UserIcon',
    src: 'demographics-icon-white.svg',
    route: { name: 'apps-hr-form', params: { action: 'edit' } },
    action: 'read',
    resource: 'CaregiverMenu',
  },
  {
    title: 'My Clients',
    icon: 'UserIcon',
    route: 'my-clients',
    action: 'read',
    resource: 'CaregiverMenu',
  },
  // {
  //   title: 'Attendance',
  //   icon: 'ClockIcon',
  //   route: 'attendance',
  //   action: 'read',
  //   resource: 'CaregiverMenu',
  // },
  // {
  //   title: 'Schedule',
  //   icon: 'CalendarIcon',
  //   route: 'schedule',
  //   action: 'read',
  //   resource: 'CaregiverMenu',
  // },
  // {
  //   title: 'Tasks',
  //   icon: 'CheckSquareIcon',
  //   route: 'agenttasks',
  //   action: 'read',
  //   resource: 'CaregiverMenu',
  // },
  // {
  //   title: 'Notes',
  //   icon: 'ClipboardIcon',
  //   route: 'agentnotes',
  //   action: 'read',
  //   resource: 'CaregiverMenu',
  // },
  {
    title: 'Client Dashboard',
    icon: 'HomeIcon',
    route: 'clientdashboard',
    action: 'read',
    resource: 'ClientMenu',
  },
  {
    title: 'Care Plan',
    // icon: 'ClipboardIcon',
    src: 'client-nav-icons/careplan_icon.svg',
    route: 'care-plan',
    action: 'read',
    resource: 'ClientMenu',
  },
  {
    title: 'Family',
    // icon: 'UsersIcon',
    src: 'client-nav-icons/family_icon.svg',
    route: 'client-client-family',
    action: 'read',
    resource: 'ClientMenu',
  },
  {
    title: 'Home Safety Checklist',
    // icon: 'CheckSquareIcon',
    src: 'client-nav-icons/homesafety_icon.svg',
    route: 'client-client-checklist',
    action: 'read',
    resource: 'ClientMenu',
  },
  {
    title: 'Care Assessment',
    // icon: 'HeartIcon',
    src: 'client-nav-icons/assessmentcare_icon.svg',
    // route: 'client-assessmentcare',
    route: { name: 'client-assessmentcare', params: { tabIndex: `tab-form-${0}` } },
    action: 'read',
    resource: 'ClientMenu',
  },
  /* {
    title: 'Carelogs',
    icon: 'BookOpenIcon',
    route: 'client-client-carelogs',
    action: 'read',
    resource: 'ClientMenu',
  }, */
  {
    title: 'Caregiver Preferences',
    icon: 'DiscIcon',
    route: 'client-client-preferences',
    action: 'read',
    resource: 'ClientMenu',
  },
]
