<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="$store.state.appConfig.notifications.filter(f => f.status === 1).length"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notifications
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ $store.state.appConfig.notifications.filter(f => f.status === 1).length }} New
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <b-link
        v-for="notification in notifications"
        :key="notification.id"
        :v-else-if="notification.data.length > 0"
        :href="notification.data[userType].router ? $router.resolve({ name: notification.data[userType].router.name, params: { action: notification.data[userType].router.params.action }, query: { id: notification.data[userType].router.query.id } }).href : $router.resolve({ name: calendarRoute }).href"
        target="_blank"
      >
        <b-media
          :class="notification.status === 1 ? 'bg-light-primary' : ''"
          @click="updateNotifStatus({ids: [notification.id], userType})"
        >
          <template #aside>
            <b-avatar
              :src="['Form Update Request', 'Caregiver Shift Cancellation', 'Pending Confirmation for Open Shift'].includes(notification['data'][userType]['title'])
                ?
                  (notification['caregiver_picture'] ? `${url}upload/${notification.caregiver_id}/${notification['caregiver_picture']}` : null)
                :
                  (['Form Update Approved', 'Form Update Denied', 'Request for Open Shift Approved'].includes(notification['data'][userType]['title'])
                    ?
                      null
                    :
                      (notification['client_picture'] ? `${url}upload/clients/${notification['client_picture']}` : null)
                  )"
              :text="['Form Update Approved', 'Form Update Denied', 'Request for Open Shift Approved'].includes(notification['data'][userType]['title'])
                ?
                  'AD'
                :
                  null"
              size="32"
              variant="light-primary"
            >
              <feather-icon
                v-if="!notification['data'][userType]['title'].includes('Form')"
                icon="CalendarIcon"
                size="18"
              />
              <feather-icon
                v-if="notification['data'][userType]['title'].includes('Form')"
                icon="FileTextIcon"
                size="18"
              />
            </b-avatar>
          </template>
          <p class="media-heading d-flex justify-content-between">
            <span
              class="font-weight-bolder mr-1"
              :class="notification.status === 1 ? 'text-primary' : ''"
            >
              {{ notification['data'][userType]['title'] }}
            </span>
            <small class="notification-text d-block">
              <span style="font-size: 10px;"> {{ modifyDate(notification.timestamp) }} </span>
            </small>
          </p>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <small class="notification-text"><span v-html="notification['data'][userType]['subtitle'].replace(/(<([^>]+)>)/gi, '')" /></small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer"><b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
      to="/notifications"
      @click="updateNotifStatus({ids: $store.state.appConfig.notifications.filter(f => f.status === 1).map(f => f.id), userType})"
    >Read all notifications</b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import store from '@/store'
import SockJS from 'sockjs-client'
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'

import moment from 'moment'

import Vue from 'vue'
// eslint-disable-next-line no-unused-vars
import { ref, computed, watch } from '@vue/composition-api'

import '@/assets/scss/custom/notification.scss'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    // BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  setup() {
    const sock = new SockJS('https://socket.motivit.com:443/echo')

    const sockId = 'coc-n01'

    const { role, id } = JSON.parse(localStorage.userData)

    const userType = role === 'agent' ? 'caregiver' : role

    const notifications = ref(computed(() => store.state.appConfig.notifications))
    const calendarRoute = ref(computed(() => {
      if (userType === 'admin') return 'apps-calendar'
      if (userType === 'caregiver') return 'shift-calendar'
      return 'clientcalendar'
    }))

    const getSockMsg = sockMsg => {
      if (sockMsg === true) {
        // Trigger reload or something here
        // console.log(typeof sockMsg, sockMsg)
        // eslint-disable-next-line no-use-before-define
        fetchNotifications()
      }
    }

    // eslint-disable-next-line no-unused-vars
    const sendSockMsg = sockMsg => {
      sock.send(JSON.stringify({ id: sockId, msg: sockMsg }))
    }

    const initializeSockJs = () => {
      sock.onopen = () => {
      // console.log('Connection established.')
      }

      sock.onmessage = event => {
        const data = JSON.parse(event.data)
        if (data.id === sockId) {
          getSockMsg(data.msg)
        }
      }

      sock.onclose = () => {
      // console.warn('Connection closed.')
        SockJS.call(sock, sock.url)
      }
    }

    // const notifications = ref()
    const modifyDate = param => {
      // const date = moment(param.split(' ')[0], 'ddd MMM DD YYYY HH:mm:ss GMT Z').diff(Date.now(), 'days')
      const date = moment(param.split(' ')[0]).format('MMM DD, YYYY')
      const time = param.split(' ')[1]
      const h = time.split(':')[0]
      const m = time.split(':')[1]
      const eventdate = moment(param.split(' ')[0])
      const todaysdate = moment()
      const difference = eventdate.diff(todaysdate, 'days')
      const displayDate = difference < 0 ? `${date} at ${h}:${m}` : moment(param.split(' ')[0]).fromNow()
      // console.log('today: ', displayDate)
      return displayDate
    }
    // moment(param.split(' ')[0]).format('ddd, hA')

    // GET ALL NOTIFICATIONS
    const fetchNotifications = () => {
      // console.log(id)
      axios
        // .post('https://coc-dev.motivit.com/api/calendar/fetchNotifications', {
        .post(`${Vue.prototype.$apiUrl2}clientCalendar/fetchNotifications `,
          {
            // eslint-disable-next-line object-shorthand
            user: userType,
            userId: id,
          },
          {
            headers: {
              'Content-type': 'application/json',
            },
          })
        .then(response => {
          const data = response.data.response.data.filter(f => f.data[userType].length !== 0)
          // eslint-disable-next-line array-callback-return
          // const modified = data.fprEach(element => {
          //   temp.push({
          //     ...element,
          //     data: element.data[userType].subtitle.replace(/(<([^>]+)>)/gi, '')
          //   })
          //   return {
          //     element.data[userType].subtitle.replace(/(<([^>]+)>)/gi, '')
          //   }
          // })
          // const res = str.match(/\d{2}([/.-])\d{2}\1\d{4}/g) || []
          // const res = str.match(/(\d{4}([.\-/ ])\d{2}\2\d{2}|\d{2}([.\-/ ])\d{2}\3\d{4})/) || []
          // if (res.length > 0) {
          //   const date = moment(res[0].toString()).format('MMM DD, YYYY')
          //   const newVal = str.replace(res[0], date)
          //   console.log('notif:res ', newVal)
          // eslint-disable-next-line no-param-reassign
          // element.data[userType].subtitle = newVal
          // }
          // temp.push(...element)
          // console.log('notif:str ', str)
          store.commit('appConfig/UPDATE_NOTIFICATION', data) /* filter if data.usertype is empty */
        })
        .catch(error => {
          console.log(error)
        })
    }

    fetchNotifications()
    initializeSockJs()

    /* eslint-disable global-require */
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const updateNotifStatus = async data => {
      try {
        const response = await axios.post(`${Vue.prototype.$apiUrl2}clientCalendar/updateNotifications `,
          JSON.stringify(data),
          {
            headers: {
              'Content-type': 'application/json',
            },
          })

        sendSockMsg(response.data.response.data)
      } catch (error) {
        console.log(error)
      }
    }

    return {
      notifications,
      fetchNotifications,
      getSockMsg,
      url: Vue.prototype.$mainUrl,
      perfectScrollbarSettings,
      userType,
      updateNotifStatus,
      modifyDate,
      calendarRoute,
    }
  },
  methods: {
    moment() {
      return moment()
    },
  },
}
</script>

<style>

</style>
