<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user py-0"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0 text-capitalize">
          {{ userData.fullName || userData.username }}
        </p>
        <span
          v-if="userData.role !== 'agent' && (userData.family_member === 'no' || userData.family_member === null)"
          class="user-status"
        >
          {{ userData.role }}
        </span>
        <span
          v-else-if="userData.role === 'agent' && (userData.family_member === 'no' || userData.family_member === null)"
          class="user-status"
        >
          caregiver
        </span>
        <span
          v-else
          class="user-status"
        >
          {{ userData.family_member }}
        </span>
      </div>
      <b-avatar
        size="40"
        :src="profImg || '/img/user_icon.png'"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          v-if="!userData.fullName"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>

    <template>
      <!-- Change Password Modal  -->
      <b-modal
        id="modal-changePW"
        ref="changePW-modal"
        title="Change Password"
        centered
      >
        <div class="mx-0.5 my-1">
          <strong class="text-capitalize">Username: </strong> {{ userData.email }}
          <validation-observer ref="simpleRules">
            <b-form>
              <b-form-group
                label="* New Password"
                label-for="new-password"
                class="mt-1"
              >
                <label
                  class="sr-only"
                  for="new-password"
                />
                <validation-provider
                  #default="{ errors }"
                  name="New Password"
                  rules="required"
                >
                  <b-form-input
                    id="new-password"
                    v-model="form.newPassword"
                    type="password"
                    :state="errors.length > 0 ? false : null"
                    placeholder="New Password"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label="* Confirm New Password"
                label-for="confirm-password"
              >
                <label
                  class="sr-only"
                  for="confirm-password"
                />
                <validation-provider
                  #default="{ errors }"
                  name="Confirm New Password"
                  rules="required"
                >
                  <b-form-input
                    id="confirm-password"
                    v-model="form.confirmPassword"
                    type="password"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Confirm New Password"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

            </b-form>
          </validation-observer>
        </div>

        <template #modal-footer="{ cancel }">
          <b-button
            variant="outline-dark"
            @click="cancel()"
          >
            Cancel
          </b-button>
          <b-button
            variant="secondary"
            @click="validationForm('change_password')"
          >
            Save
          </b-button>
        </template>
      </b-modal>
    </template>

    <b-dropdown-item
      :to="{ name: 'pages-account-setting' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="SettingsIcon"
        class="mr-50"
      />
      <span>Settings</span>
    </b-dropdown-item>
    <!-- <b-dropdown-divider /> -->
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Logout</span>
    </b-dropdown-item></b-nav-item-dropdown>
</template>

<script>
import axios from '@axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  // eslint-disable-next-line
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BModal, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'
import { ref, computed } from '@vue/composition-api'
import Swal from 'sweetalert2'
import store from '@/store'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    // eslint-disable-next-line
    BDropdownDivider,
    BAvatar,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,

    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    const profImg = ref(computed(() => store.state.account_setting.general.profile_picture))
    const form = ref({})
    const input = ref(true)

    const fetchUserInfo = () => {
      const uData = JSON.parse(localStorage.getItem('userData'))
      const accessToken = localStorage.getItem('accessToken')
      axios
        /* eslint-disable */
        .get(process.env.VUE_APP_API_BASE_URL2 + 'users/getUserInfo?id=' + uData.id,
          {
            headers: {
              'Authorization': `Bearer ${accessToken}`,
              'Cache-Control': 'no-store',
              'Content-Type': 'application/form-data',
            },
          },
        )
        .then(response => {
          let data = response.data.response.data
          store.commit('account_setting/SET_PROF_PIC', data.avatar)
        })
        /* eslint-enable */
    }
    fetchUserInfo()

    return {
      form,
      input,
      profImg,
    }
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
    }
  },
  methods: {
    /* myForms() {
      const routerResolve = this.$router.resolve({ name: 'apps-hr-form', params: { action: 'edit' }, query: { id: (this.userData !== null) ? this.userData.cbiId : null } })
      window.open(routerResolve.href, '_blank')
    }, */
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      localStorage.removeItem('perstate')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
    validationForm(key) {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if (key === 'change_password') {
            this.changePasswordAction()
          }
        }
      })
    },
    changePasswordAction() {
      const formData = {
        id: this.userData.id,
        data: this.form,
      }
      // eslint-disable-next-line
      this.$http.post(this.$apiUrl2 + 'users/changePassword', {
        param: formData,
      },
      {
        headers: {
          'Content-type': 'application/json',
        },
      })
        .then(response => {
          console.log(response)
          if (response.data === 'not_match') {
            Swal.fire({
              icon: 'warning',
              title: 'New and Confirm Password does not Match!',
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          } else {
            this.form.newPassword = ''
            this.form.confirmPassword = ''

            this.$refs['changePW-modal'].hide()

            Swal.fire({
              icon: 'success',
              title: 'Password Succesfully Updated',
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
        })
    },
  },
}
</script>

<style>
.header-navbar .navbar-container ul.navbar-nav li.dropdown-user .dropdown-menu {
    width: 16rem !important;
}
</style>
