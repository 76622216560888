var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-nav-item-dropdown',{staticClass:"dropdown-notification mr-25",attrs:{"menu-class":"dropdown-menu-media","right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body",attrs:{"badge":_vm.$store.state.appConfig.notifications.filter(function (f) { return f.status === 1; }).length,"badge-classes":"bg-danger","icon":"BellIcon","size":"21"}})]},proxy:true}])},[_c('li',{staticClass:"dropdown-menu-header"},[_c('div',{staticClass:"dropdown-header d-flex"},[_c('h4',{staticClass:"notification-title mb-0 mr-auto"},[_vm._v(" Notifications ")]),_c('b-badge',{attrs:{"pill":"","variant":"light-primary"}},[_vm._v(" "+_vm._s(_vm.$store.state.appConfig.notifications.filter(function (f) { return f.status === 1; }).length)+" New ")])],1)]),_c('vue-perfect-scrollbar',{staticClass:"scrollable-container media-list scroll-area",attrs:{"settings":_vm.perfectScrollbarSettings,"tagname":"li"}},_vm._l((_vm.notifications),function(notification){return _c('b-link',{key:notification.id,attrs:{"v-else-if":notification.data.length > 0,"href":notification.data[_vm.userType].router ? _vm.$router.resolve({ name: notification.data[_vm.userType].router.name, params: { action: notification.data[_vm.userType].router.params.action }, query: { id: notification.data[_vm.userType].router.query.id } }).href : _vm.$router.resolve({ name: _vm.calendarRoute }).href,"target":"_blank"}},[_c('b-media',{class:notification.status === 1 ? 'bg-light-primary' : '',on:{"click":function($event){return _vm.updateNotifStatus({ids: [notification.id], userType: _vm.userType})}},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"src":['Form Update Request', 'Caregiver Shift Cancellation', 'Pending Confirmation for Open Shift'].includes(notification['data'][_vm.userType]['title'])
              ?
                (notification['caregiver_picture'] ? (_vm.url + "upload/" + (notification.caregiver_id) + "/" + (notification['caregiver_picture'])) : null)
              :
                (['Form Update Approved', 'Form Update Denied', 'Request for Open Shift Approved'].includes(notification['data'][_vm.userType]['title'])
                  ?
                    null
                  :
                    (notification['client_picture'] ? (_vm.url + "upload/clients/" + (notification['client_picture'])) : null)
                ),"text":['Form Update Approved', 'Form Update Denied', 'Request for Open Shift Approved'].includes(notification['data'][_vm.userType]['title'])
              ?
                'AD'
              :
                null,"size":"32","variant":"light-primary"}},[(!notification['data'][_vm.userType]['title'].includes('Form'))?_c('feather-icon',{attrs:{"icon":"CalendarIcon","size":"18"}}):_vm._e(),(notification['data'][_vm.userType]['title'].includes('Form'))?_c('feather-icon',{attrs:{"icon":"FileTextIcon","size":"18"}}):_vm._e()],1)]},proxy:true}],null,true)},[_c('p',{staticClass:"media-heading d-flex justify-content-between"},[_c('span',{staticClass:"font-weight-bolder mr-1",class:notification.status === 1 ? 'text-primary' : ''},[_vm._v(" "+_vm._s(notification['data'][_vm.userType]['title'])+" ")]),_c('small',{staticClass:"notification-text d-block"},[_c('span',{staticStyle:{"font-size":"10px"}},[_vm._v(" "+_vm._s(_vm.modifyDate(notification.timestamp))+" ")])])]),_c('small',{staticClass:"notification-text"},[_c('span',{domProps:{"innerHTML":_vm._s(notification['data'][_vm.userType]['subtitle'].replace(/(<([^>]+)>)/gi, ''))}})])])],1)}),1),_c('li',{staticClass:"dropdown-menu-footer"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary","block":"","to":"/notifications"},on:{"click":function($event){_vm.updateNotifStatus({ids: _vm.$store.state.appConfig.notifications.filter(function (f) { return f.status === 1; }).map(function (f) { return f.id; }), userType: _vm.userType})}}},[_vm._v("Read all notifications")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }