<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block app-footer">
      ©{{ currentYear }} Circle Of Care. All Rights Reserved. Powered by <b-link
        href="https://www.motivit.com"
        target="_blank"
      >MotivIT</b-link>
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
    }
  },
}
</script>
